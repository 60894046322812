@import "variables";
@import "mixins";

.logo {
  display: block;

  &-list {
    display: flex;
    align-items: center;

    &__item {

      &:not(:first-child) {
        margin-left: 35px;

        @include respond-to(small_tablet) {
          margin-left: 10px;
        }
      }
    }
  }

  &--wwf {
    width: 70px;
    height: 79px;

    @include respond-to(small_tablet) {
      width: 40px;
      height: 49px;
    }

    &-small {
      width: 60px;
      height: 69px;

      @include respond-to(small_tablet) {
        width: 40px;
        height: 49px;
      }
    }
  }

  &--kinder {
    width: 113px;
    height: 80px;

    @include respond-to(small_tablet) {
      width: 57px;
      height: 40px;
    }

    &-small {
      width: 99px;
      height: 70px;

      @include respond-to(small_tablet) {
        width: 57px;
        height: 40px;
      }
    }
  }
}


