@import 'variables';
@import 'mixins';

.slider {
  position: relative;

  @include respond-to(tablet) {
    padding-bottom: 90px;
  }

  .btn {

    &-prev,
    &-next {
      position: absolute;
      top: calc(50% - 20px);
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border: 2px solid $pomegranate;
      border-radius: 50%;
      cursor: pointer;

      @include respond-to(tablet) {
        top: auto;
        bottom: 0;
        transform: initial;
      }
    }

    &-prev {
      left: -90px;

      @include respond-to(tablet) {
        left: 0;
      }

      svg {
        margin-left: -3px;
      }
    }

    &-next {
      right: -90px;

      @include respond-to(tablet) {
        right: 0;
      }

      svg {
        margin-left: 3px;
      }
    }
  }
}

