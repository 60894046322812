@import "variables";
@import "mixins";

.article {

  &__header {
    padding-bottom: calc(max(40px, 5vw));
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__title {
    position: relative;
    z-index: 10;
    margin-top: calc(max(100px, 18vw));
    text-align: center;
    color: $white;

    .title {

      &--xxxl {
    
        @include respond-to(desktop) {
          font-size: 9.6rem;
        }
    
        @include respond-to(small_tablet) {
          font-size: 6.8rem;
        }
      }

      &--md {
    
        @include respond-to(desktop) {
          font-size: 4.2rem;
        }
    
        @include respond-to(small_tablet) {
          font-size: 3rem;
        }
      }
    }
  }

  &__subtitle {
    margin-bottom: 11px;
    font-size: 3.6rem;
    font-weight: 300;
    font-style: italic;
    line-height: 120%;

    @include respond-to(small_tablet) {
      margin-bottom: 16px;
      font-size: 2.4rem;
    }
  }

  &__text {

    .subtitle {
      margin-top: 40px;
    }

    .logo {

      &-list {
        justify-content: center;
        margin-top: 40px;

        &__item {

          &:not(:first-child) {
    
            @include respond-to(small_tablet) {
              margin-left: 35px;
            }
          }
        }
      }

      &--wwf {
    
        @include respond-to(small_tablet) {
          width: 80px;
          height: 80px;
        }
      }
    
      &--kinder {
    
        @include respond-to(small_tablet) {
          width: 113px;
          height: 80px;
        }
      }
    }

    .text {
      margin-top: 20px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &--center {
      text-align: center;

      .article {

        &__icon {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }

    &--black {
      color: #000;
    }
  }

  &__icon {
    height: 80px;
    margin-bottom: 40px;

    &--center {
      margin-left: auto;
      margin-right: auto;
    }

    &--saigak {
      width: 74px;
      height: 100px;

      @include respond-to(small_tablet) {
        width: 59px;
        height: 80px;
      }
    }

    &--horns {
      width: 111px;

      @include respond-to(small_tablet) {
        width: 87px;
        height: 63px;
      }
    }

    &--nose {
      width: 77px;

      @include respond-to(small_tablet) {
        width: 58px;
        height: 60px;
      }
    }

    &--book {
      width: 95px;

      @include respond-to(small_tablet) {
        width: 72px;
        height: 60px;
      }
    }

    &--hands {
      width: 175px;
      height: 144px;

      @include respond-to(small_tablet) {
        width: 122px;
        height: 100px;
      }
    }

    &--fire {
      width: 67px;
      height: 100px;
    }

    &--sun {
      width: 79px;
      height: 85px;
    }

    &--camera {
      width: 58px;
      height: 45px;

      @include respond-to(small_tablet) {
        display: none;
      }
    }
  }

  &__img {
    max-width: 100%;

    &--md {
      width: 536px;
      height: 544px;

      @include respond-to(desktop) {
        width: 360px;
        height: 365px;
      }

      @include respond-to(small_tablet) {
        width: 280px;
        height: 283px;
      }
    }

    &--xl {
      width: 597px;
      height: 606px;

      @include respond-to(desktop) {
        width: 376px;
        height: 377px;
      }

      @include respond-to(small_tablet) {
        width: 275px;
        height: 276px;
      }
    }
  }

  &__facts {
    display: flex;
    justify-content: flex-end;

    @include respond-to(laptop) {
      justify-content: center;
    }
  }

  &__video {
    
    .subtitle {
      margin-bottom: 40px;

      @include respond-to(desktop) {
        margin-bottom: 60px;
      }

      @include respond-to(small_tablet) {
        margin-bottom: 40px;
      }
    }
  }
}

.section {
  position: relative;
  z-index: 10;
  padding: 60px 0;

  @include respond-to(tablet) {
    padding: 50px 0;
  }

  @include respond-to(small_tablet) {
    padding: 30px 0;
  }

  .subtitle {
    text-align: center;

    &--question {
      color: #000;
      margin-bottom: 20px;
      text-align: left;
    }
  }

  &__content {
    display: flex;
    align-items: center;

    @include respond-to(tablet) {
      flex-direction: column;
    }

    &-item {

      &:not(:first-child) {
        margin-left: 35px;

        @include respond-to(tablet) {
          margin-top: 40px;
          margin-left: 0;
        }
      }
    }
  }

  &-wave {
    margin-top: -184px;
    padding: 260px 0;

    @include respond-to(1920px) {
      margin-top: -134px;
      padding: 210px 0;
    }

    @include respond-to(laptop) {
      margin-top: -96px;
    }

    @include respond-to(small_tablet) {
      padding: 110px 0;
    }
  }

  &--intro {
    padding-top: 90px;
    text-align: center;

    @include respond-to(laptop) {
      padding-top: 60px;
    }
  }

  &--bg {
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    &-intro {
      margin-top: -2px;
      padding-top: 100px;
      background-color: $cerulean;

      @include respond-to(small_tablet) {
        padding-top: 40px;
      }
    }

    &-frame {
      height: 379px;
      margin-top: -287px;
      padding: 0;
      background-image: url(../img/content/bg/img_003.png);

      @include respond-to(1920px) {
        height: 279px;
        margin-top: -177px;
      }

      @include respond-to(desktop) {
        height: 210px;
        margin-top: -120px;
      }

      @include respond-to(laptop) {
        height: 190px;
      }

      @include respond-to(tablet) {
        height: 180px;
      }

      @include respond-to(small_tablet) {
        height: 120px;
        margin-top: -60px;
      }
    }

    &-trees {
      height: 343px;
      margin-top: -2px;
      padding: 0;
      background-image: url(../img/content/bg/img_004.png);
      background-color: $cerulean;

      @include respond-to(desktop) {
        height: 243px;
      }

      @include respond-to(tablet) {
        height: 183px;
      }

      @include respond-to(small_tablet) {
        background-position: left center;
      }
    }

    &-grass {
      height: 473px;
      padding: 0;
      background-image: url(../img/content/bg/img_005.png);

      @include respond-to(desktop) {
        height: 373px;
      }

      @include respond-to(tablet) {
        height: 183px;
      }
    }

    &-quote {
      background-image: url(../img/content/bg/img_006.svg);

      .section {

        &__content {

          @include respond-to(tablet) {
            flex-direction: row;
            align-items: initial;
          }

          @include respond-to(small_tablet) {
            flex-direction: column;
            align-items: center;
          }

          &-item {

            &:not(:first-child) {

              @include respond-to(tablet) {
                margin-top: 0;
                margin-left: 20px;
              }

              @include respond-to(small_tablet) {
                margin-top: 20px;
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    &-facts {
      background-image: url(../img/content/bg/img_007.jpg);
      background-position: center;
      background-attachment: fixed;

      // @include respond-to(laptop) {
      //   background-position: center right;
      // }
    }

    &-saigaks {
      margin: 60px 0;
      padding: 210px 0 250px;
      background-image: url(../img/content/bg/img_010.jpg);
      color: $white;

      @include respond-to(tablet) {
        padding: 110px 0 140px;
      }

      .subtitle {
        
        &::after {
          content: '';
          display: none;
          width: 60px;
          height: 60px;
          margin: 0 auto;
          background-image: url(../img/general/icon/icon_move.svg);
          background-position: center;
          background-size: auto;
          background-repeat: no-repeat;

          @include respond-to(small_tablet) {
            display: block;
          }
        }
      }
    }

    &-cerulean {
      margin-top: -2px;
      padding-top: 0;
      padding-bottom: 95px;
      background-color: $cerulean;
      color: $white;

      @include respond-to(laptop) {
        padding-bottom: 100px;
      }

      @include respond-to(small_tablet) {
        padding-top: 20px;
        padding-bottom: 80px;
      }
    }

    &-slider {
      z-index: 5;
      margin-top: -120px;
      padding-top: 160px;
      padding-bottom: 180px;
      background-image: url(../img/content/bg/img_013.svg);

      @include respond-to(small_tablet) {
        padding-bottom: 140px;
      }

      & + .section {
        margin-top: -100px;

        @include respond-to(small_tablet) {
          margin-top: -60px;
        }
      }
    }

    &-figure {
      z-index: 5;
      margin-top: -280px;
      padding-top: 290px;
      padding-bottom: 150px;
      background-image: url(../img/content/bg/img_014.svg);

      @include respond-to(small_tablet) {
        margin-top: -130px;
        padding-top: 190px;
        padding-bottom: 140px;
      }

      & + .section {
        margin-top: -80px;
      }

      &--small {
        background-position: center;
        background-size: contain;
        padding-bottom: 80px;
      }
    }
  }

  &--gradient {

    &-chalky {
      margin-top: -2px;
      background-image: linear-gradient(180deg, #eed094 0%, rgba(238, 208, 148, 0) 100%);
      color: $white;

      .article {

        &__video {
          margin-top: -100px;

          @include respond-to(tablet) {
            margin-top: -50px;
          }
        }
      }
    }
  }

  &--wave {
    z-index: 20;
    padding: 0;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    &-white {
      height: 186px;
      background-image: url(../img/content/bg/img_008.png);

      @include respond-to(1920px) {
        height: 136px;
      }

      @include respond-to(laptop) {
        height: 96px;
      }
    }

    &-cerulean {
      height: 188px;
      
      background-image: url(../img/content/bg/img_009.png);

      @include respond-to(1920px) {
        height: 138px;
      }

      @include respond-to(laptop) {
        height: 98px;
      }
    }

    &-bottom {
      margin-top: -183px;

      @include respond-to(1920px) {
        margin-top: -133px;
      }

      @include respond-to(small_tablet) {
        margin-top: -93px;
      }
    }
  }

  &--zoom-img {
    z-index: 20;
  }

  &--move-up {
    padding-bottom: 0;

    .container {
      margin-top: -160px;

      @include respond-to(small_tablet) {
        margin-top: -70px;
      }
    }
  }
}

.video {
  position: relative;
  height: 640px;
  mask-image: url(../img/content/mask_001.png);
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;

  @include respond-to(tablet) {
    height: 511px;
  }

  @include respond-to(small_tablet) {
    height: 0;
    padding-top: 70%;
  }

  @include respond-to(phone) {
    padding-top: 75%;
  }

  &.active {
    mask-image: initial;
  }

  .btn-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
  }

  &__poster,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__poster {
    z-index: 10;
  }
}

.blockquote {

  &::before {
    content: '';
    display: block;
    width: 100px;
    height: 80px;
    margin-bottom: 30px;
    background-image: url(../img/general/icon/icon_quotes.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__cite {
    display: block;
    margin-top: 30px;
    font-style: normal;

    @include respond-to(small_tablet) {
      margin-top: 20px;
    }
  }

  &-bg {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 918px;
    box-sizing: border-box;
    color: $white;

    @include respond-to(desktop) {
      display: block;
      align-items: initial;
      width: calc(100% + 60px);
      margin-left: -30px;
      padding-top: 80px;
    }

    @include respond-to(small_tablet) {
      width: calc(100% + 40px);
      min-height: 634px;
      margin-top: 50px;
      margin-left: -20px;
      padding-right: 20px;
      padding-left: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 60px;
      right: 30px;
      width: 240px;
      height: 240px;
      background-image: url(../img/general/icon/icon_quotes_decor.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      @include respond-to(desktop) {
        display: none;
      }

      @include respond-to(small_tablet) {
        top: -62px;
        display: block;
        width: 123px;
        height: 123px;
      }
    }

    &__img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;

      img {

        @include respond-to(desktop) {
          object-fit: cover;
        }
      }
    }

    &__content {
      width: 570px;
      max-width: 100%;
      margin-left: 220px;

      @include respond-to(desktop) {
        width: 470px;
      }

      @include respond-to(laptop) {
        width: 350px;
      }

      @include respond-to(tablet) {
        margin-left: calc(max(30px, 10vw));
      }

      @include respond-to(small_tablet) {
        margin-left: 0;
      }
    }
  }
}

.saigaks-num {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 80px;
  text-align: center;

  @include respond-to(laptop) {
    margin-top: 40px;
  }

  @include respond-to(small_tablet) {
    margin-top: 5px;
    overflow-x: auto;
  }

  &__item {

    @include respond-to(small_tablet) {
      flex-shrink: 0;
    }

    &:not(:first-child) {
      margin-left: 20px;

      .saigaks-num {

        &__title {
          font-size: 4.1rem;

          @include respond-to(laptop) {
            font-size: 3rem;
          }
        }
      }
    }

    &:nth-child(1) {

      .saigaks-num {

        &__img {
          width: 200px;

          @include respond-to(laptop) {
            width: 139px;
          }
        }
      }
    }

    &:nth-child(2) {

      .saigaks-num {

        &__img {
          width: 160px;

          @include respond-to(laptop) {
            width: 111px;
          }
        }
      }
    }

    &:nth-child(3) {

      .saigaks-num {

        &__img {
          width: 137px;

          @include respond-to(laptop) {
            width: 95px;
          }
        }
      }
    }

    &:nth-child(4) {

      .saigaks-num {

        &__img {
          width: 106px;

          @include respond-to(laptop) {
            width: 74px;
          }
        }
      }
    }

    &:nth-child(5) {

      .saigaks-num {

        &__img {
          width: 111px;

          @include respond-to(laptop) {
            width: 77px;
          }
        }
      }
    }
  }

  &__img {
    height: 163px;
    margin: 0 auto;
    background-image: url(../img/general/icon/saigak/icon_saigak_white.svg);
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(-1, 1);

    @include respond-to(laptop) {
      height: 113px;
    }
  }

  &__title {
    margin-top: 20px;

    @include respond-to(laptop) {
      font-size: 3rem;
    }
  }

  &__year {
    margin-top: 10px;
  }
}

.facts {
  width: 560px;
  max-width: 100%;
  color: $white;

  &__tabs {
    display: flex;
    align-items: center;
    margin-top: 70px;

    @include respond-to(small_tablet) {
      flex-wrap: wrap;
      margin-top: 0;
      margin-left: -10px;
      padding-top: 30px;
    }

    &-item {
      flex-shrink: 0;
      width: 60px;
      height: 60px;
      border: 1px solid $pomegranate;
      border-radius: 50%;
      box-sizing: border-box;
      line-height: 1;
      text-align: center;
      color: inherit;
      cursor: pointer;
      transition: $basicTransition;

      @include respond-to(small_tablet) {
        width: 50px;
        height: 50px;
        margin-top: 10px;
        margin-left: 10px;
      }

      &:not(:first-child) {
        margin-left: 25px;

        @include respond-to(small_tablet) {
          margin-left: 10px;
        }
      }

      &:hover {
        background-color: $pomegranate;
      }

      &.active {
        border-color: $white;
        pointer-events: none;
      }
    }
  }

  &__content {
    margin-top: 40px;

    &-item {
      display: none;
      min-height: 324px;

      &.show {
        display: block;
      }
    }
  }
}

.footprint {
  position: absolute;
  top: calc(50% + 50px);
  width: 135px;
  height: 489px;

  @include respond-to(laptop) {
    display: none;
  }

  &__item {
    position: absolute;
    width: 28px;
    height: 29px;
    background-image: url(../img/general/icon/icon_footprint.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &:nth-child(1) {
      top: 1%;
      left: 75%;
      transform: rotate(26deg) !important;
    }

    &:nth-child(2) {
      top: 8%;
      left: 27%;
      transform: rotate(0deg) !important;
    }

    &:nth-child(3) {
      top: 25%;
      left: 55%;
      transform: rotate(32deg) !important;
    }

    &:nth-child(4) {
      top: 33%;
      left: 1%;
      transform: rotate(-11deg) !important;
    }

    &:nth-child(5) {
      top: 50%;
      left: 29%;
      transform: rotate(36deg) !important;
    }

    &:nth-child(6) {
      top: 68%;
      left: 65%;
      transform: rotate(26deg) !important;
    }

    &:nth-child(7) {
      top: 76%;
      left: 11%;
      transform: rotate(-11deg) !important;
    }

    &:nth-child(8) {
      top: 93%;
      left: 38%;
      transform: rotate(36deg) !important;
    }
  }

  &--left {
    right: calc(100% + 120px);
    transform: translateY(-50%);

    @include respond-to(desktop) {
      right: calc(100% + 60px);
    }
  }

  &--right {
    left: calc(100% + 120px);
    transform: translateY(-50%) scaleX(-1);

    @include respond-to(desktop) {
      left: calc(100% + 60px);
    }
  }

  &--hidden {
    display: none;
  }

  &--xs {
    height: 279px;

    .footprint {

      &__item {

        &:nth-child(2) {
          top: 13%;
        }

        &:nth-child(3) {
          top: 43%;
        }

        &:nth-child(4) {
          top: 57%;
        }

        &:nth-child(5) {
          top: 87%;
        }
      }
    }
  }
}

.zoom-img {
  position: relative;

  &:hover {

    &::before {
      z-index: -1;
      opacity: 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-image: url(../img/general/icon/icon_zoom.svg);
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    background-color: rgba($black, 0.75);
    transition: $basicTransition;
  }

  &--save {
    height: 1085px;

    @include respond-to(laptop) {
      height: auto;
    }
  }
}

.figure {

  figcaption {
    margin-top: 20px;
  }

  &__img {
    height: 513px;

    @include respond-to(small_tablet) {
      position: relative;
      height: 0;
      padding-top: 67%;
    }

    img {

      @include respond-to(small_tablet) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--full-height {
      height: auto;
      max-width: 100%;
      background-position: top center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(../img/content/bg/img_014.svg);

      @include respond-to(small_tablet) {
        background: none;
        padding-top: 0;
      }

      img {

        @include respond-to(small_tablet) {
          position: initial;
          top: 0;
          left: 0;
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}
