// Device dimensions

$phone: 320px;
$xxs: 374px;
$xs: 479px;
$mobile: 639px;
$sm: 767px;
$md: 1024px;
$wd: 1279px;
$lg: 1439px;
$xxl: 1919px;

// Colors

$black: #000;
$cerulean: #009fe5;
$creamCan: #f2c94c;
$mineShaft: #333;
$pomegranate: #f3410e;
$silver: #c2c2c2;
$white: #fff;

// Font size

$basicFontSize: 10px;
$oswald: 'Oswald', 'Helvetica', sans-serif;
$rubik: 'Rubik', 'Helvetica', sans-serif;

//others
$basicOverlay: rgba($black, 0.5);
$basicTransition: all 0.3s;
