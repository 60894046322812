@import "variables";
@import "mixins";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 45px;
  box-sizing: border-box;

  @include respond-to(laptop) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @include respond-to(small_tablet) {
    padding: 10px 20px;
  }

  .social-list {
    position: absolute;
    top: 53px;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;
    padding: 40px 12px;

    &__item {

      &:not(:first-child) {
        margin-top: 25px;
        margin-left: 0;
      }
    }
  }

  .btn-share {

    &.active {
      transform: rotate(90deg);
    }
  }

  &__item {

    &:not(:first-child) {
      margin-left: 40px;

      @include respond-to(small_tablet) {
        margin-left: 10px;
      }
    }
  }
}
