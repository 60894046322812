@import "variables";
@import "mixins";

.test {
  margin-top: 40px;

  .btn {
    margin-top: 30px;

    @include respond-to(small_tablet) {
      margin-top: 20px;
    }

    @include respond-to(phone) {
      width: 100%;
    }
  }

  &__intro,
  &__content,
  &__result {
    margin-top: 50px;

    @include respond-to(small_tablet) {
      margin-top: 30px;
    }
  }

  &__header {
    text-align: center;

    span {
      display: block;

      &:first-child {
        font-size: 3.6rem;
        font-weight: 300;
        font-style: italic;
        line-height: 130%;

        @include respond-to(small_tablet) {
          margin-bottom: 10px;
          font-size: 2.4rem;
        }
      }
    }
  }

  &__intro {
    text-align: center;
  }

  &__content {
    display: none;
    width: 570px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__result {
    display: none;
  }

  &__share {
    margin-top: 40px;

    .social-list {
      justify-content: center;
      margin-top: 20px;
    }
  }
}

.question {

  &__item {

    &:not(:first-child) {
      min-height: 57vh;
      margin-top: 30px;
      text-align: center;

      @include respond-to(small_tablet) {
        margin-top: 20px;
      }
    }
  }

  &__num {
    font-size: 2.4rem;
    font-style: italic;
    font-weight: 300;
    line-height: 150%;
    text-align: center;
  }

  &__figure {
    margin-top: 30px;

    &-img {
      width: 100%;
      height: 320px;
      mask-image: url(../img/content/mask_002.png);
      mask-position: center;
      mask-size: contain;
      mask-repeat: no-repeat;

      @include respond-to(small_tablet) {
        height: auto;
      }
    }

    figcaption {
      margin-top: 10px;
      font-size: 1.3rem;
      font-weight: 300;
      line-height: 150%;
      text-align: center;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 120%;
  }

  &__desc {
    display: none;
    margin-top: 20px;
  }
}

.answers {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &__item {
    padding: 11px 22px;
    border: 2px solid $white;
    border-radius: 60px;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 150%;
    cursor: pointer;
    transition: $basicTransition;

    @include respond-to(small_tablet) {
      font-size: 1.4rem;
    }

    &:hover {
      border-color: $pomegranate;
    }

    &:not(:first-child) {
      margin-top: 15px;
    }
  }
}

.result {
  padding-top: 50px;
  text-align: center;

  &__num {
    position: relative;
    z-index: 10;
    display: inline-block;
    animation: scale 0.5s linear;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      width: 180px;
      height: 180px;
      background-image: url(../img/general/icon/icon_polygon.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      @include respond-to(small_tablet) {
        width: 160px;
        height: 160px;
      }
    }
  }

  &__text {
    width: 500px;
    max-width: 100%;
    margin: 70px auto 0;

    @include respond-to(small_tablet) {
      margin-top: 90px;
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(0.3);
  }
  
  100% {
    transform: scale(1);
  }
}
