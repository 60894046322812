@import "variables";
@import "mixins";

.share {
  position: relative;

  &--border {

    .social-list {
      z-index: 20;
      display: none;
      box-sizing: border-box;
      border: 1px solid $pomegranate;
      border-radius: 30px;

      @include respond-to(small_tablet) {
        background-color: $black;
      }

      &.show {
        display: flex;
      }
    }
  }
}

.social-list {
  display: flex;
  align-items: center;

  &__item {
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 25px;
    }

    &:hover {

      path {
        fill: $pomegranate;
        transition: $basicTransition;
      }
    }
  }
}
