@import "variables";
@import "mixins";

.btn {
  display: inline-block;
  height: 42px;
  padding: 0 55px;
  border-radius: 40px;
  box-sizing: border-box;
  font-size: 1.4rem;
  line-height: 44px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  color: $white;
  cursor: pointer;
  transition: $basicTransition;

  @include respond-to(fablet) {
    padding: 0 30px;
  }

  &-list {
    display: flex;
    align-items: center;

    @include respond-to(fablet) {
      flex-direction: column;
    }

    &__item {
      width: 50%;
      max-width: 270px;

      @include respond-to(fablet) {
        width: 100%;
        max-width: initial;
      }

      &:not(:first-child) {
        margin-left: 30px;

        @include respond-to(fablet) {
          margin-top: 10px;
          margin-left: 0;
        }
      }

      .btn {
        width: 100%;
      }
    }
  }

  &-share {
    width: 33px;
    height: 33px;
    background-image: url(../img/general/icon/icon_share.svg);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: $basicTransition;

    &.active {
      transform: rotate(180deg);
    }
  }

  &-menu {
    position: relative;
    width: 45px;
    height: 30px;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      max-width: 45px;
      height: 3px;
      background-color: $white;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    span {
      display: block;
      width: 100%;
      max-width: 45px;
      height: 3px;
      background-color: $white;
      @include textHide();
    }
  }

  &-cross {
    width: 80px;
    height: 80px;
    background-image: url(../img/general/icon/icon_cross.svg);
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    background-color: $black;
    cursor: pointer;

    span {
      display: none;
      margin-bottom: 130px;
      font-size: 1.4rem;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $white;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    }
  }

  &-play {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-image: url(../img/general/icon/icon_play.svg);
    background-position: left 35px center;
    background-size: auto;
    background-repeat: no-repeat;
    background-color: $pomegranate;
    cursor: pointer;
    animation: pulse 1.5s infinite;

    @include respond-to(small_tablet) {
      width: 60px;
      height: 60px;
      background-position: left 20px center;
      background-size: 26px 34px;
    }

    &:hover {
      animation: none;
    }
  }

  &-home {
    display: block;
    width: 33px;
    height: 33px;
    background-image: url(../img/general/icon/icon_home.svg);
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
  }

  &--border {
    border: 1px solid $pomegranate;

    &:hover {
      background-color: $pomegranate;
    }
  }

  &--granate {
    background-color: $pomegranate;

    &:hover {
      background-color: #cc3307;
    }
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  35% {
    transform: translate(-50%, -50%) scale(0.9);
  }
    100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
