/* Your custom fonts here */

@font-face {
  font-family: 'Oswald';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: 
        url("../fonts/Oswald/Oswald-Regular.woff2") format("woff2"),
        url("../fonts/Oswald/Oswald-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Oswald';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: 
        url("../fonts/Oswald/Oswald-Bold.woff2") format("woff2"),
        url("../fonts/Oswald/Oswald-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Rubik';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: 
        url("../fonts/Rubik/Rubik-Light.woff2") format("woff2"),
        url("../fonts/Rubik/Rubik-Light.woff") format("woff");
}

@font-face {
  font-family: 'Rubik';
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: 
        url("../fonts/Rubik/Rubik-LightItalic.woff2") format("woff2"),
        url("../fonts/Rubik/Rubik-LightItalic.woff") format("woff");
}

@font-face {
  font-family: 'Rubik';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: 
        url("../fonts/Rubik/Rubik-Regular.woff2") format("woff2"),
        url("../fonts/Rubik/Rubik-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: 
        url("../fonts/Rubik/Rubik-Medium.woff2") format("woff2"),
        url("../fonts/Rubik/Rubik-Medium.woff") format("woff");
}

// Thin: 100
// ExtraLight: 200
// Light: 300
// Regular: 400
// Medium: 500
// SemiBold: 600
// Bold: 700
// ExtraBold: 800
// Black: 900
