@import "variables";
@import "mixins";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 45px;
  box-sizing: border-box;
  background-color: $black;

  @include respond-to(laptop) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @include respond-to(small_tablet) {
    padding: 10px 20px;
  }

  .social-list {
    position: absolute;
    top: 50%;
    right: 53px;
    transform: translateY(-50%);
    padding: 9px 40px;

    @include respond-to(small_tablet) {
      padding-right: 20px;
      padding-left: 20px;
    }

    &__item {

      &:not(:first-child) {
        margin-top: 0;
        margin-left: 25px;
      }
    }
  }

  &__item {
    
    &:not(:first-child) {
      margin-left: 40px;
    }
  }
}
