@import "variables";
@import "mixins";

html {
  // height: 100%;
  min-height: 100%;
  font-size: $basicFontSize;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
ol,
ul,
figure,
input,
button,
textarea,
blockquote {
  padding: 0;
  margin: 0;
}

body {
  max-width: 2560px;
  margin: 0 auto;
  height: 100%;
  font-family: $rubik;
  color: $black;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;

  &.inactive {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  // transition: $basicTransition;

  &:link {
    text-decoration: none;
  }
}

img {
  display: block;
  width: 100%;
  height: auto;

  &.cover {
    height: 100%;
    object-fit: cover;
  }

  &.contain {
    height: 100%;
    object-fit: contain;
  }
}

button,
input,
textarea,
select {
  border: none;
  background: none;
  outline: none;
}

button {

  &.disable,
  &:disabled {
    pointer-events: none;
  }
}

.title {
  font-family: $oswald;
  font-size: 4.9rem;
  font-weight: bold;
  line-height: 120%;

  br {

    @include respond-to(small_phone) {
      display: none;
    }
  }

  &--xxxl {
    font-size: 14.4rem;
    text-transform: uppercase;

    // @include respond-to(desktop) {
    //   font-size: 9.6rem;
    // }

    @include respond-to(small_tablet) {
      font-size: 6.8rem;
    }
  }

  &--xxl {
    font-size: 10.3rem;
    text-transform: uppercase;

    @include respond-to(desktop) {
      font-size: 7.2rem;
    }

    @include respond-to(small_tablet) {
      font-size: 4.8rem;
    }

    @include respond-to(phone) {
      font-size: 3.8rem;
    }
  }

  &--xl {
    font-size: 7.2rem;
    text-transform: uppercase;

    @include respond-to(desktop) {
      font-size: 4.9rem;
    }

    @include respond-to(small_tablet) {
      font-size: 3.6rem;
    }

    @include respond-to(phone) {
      font-size: 2.6rem;
    }
  }

  &--md {
    font-size: 6.4rem;
    text-transform: uppercase;

    // @include respond-to(desktop) {
    //   font-size: 4.2rem;
    // }

    @include respond-to(small_tablet) {
      font-size: 3rem;
    }
  }

  &--xs {
    font-size: 3.6rem;
    font-weight: normal;

    @include respond-to(small_tablet) {
      font-size: 2.4rem;
    }
  }
}

.subtitle {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 120%;

  &--xl {
    font-size: 3.6rem;

    @include respond-to(small_tablet) {
      font-size: 2.4rem;
    }
  }

  &--italic {
    font-style: italic;
  }

  &--light {
    font-weight: 300;
  }
}

.text {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 150%;

  &-link,
  a {
    border-bottom: 3px solid $pomegranate;
    color: inherit;
    transition: $basicTransition;

    &:hover {
      border-bottom-color: transparent;
      color: $white;
      background-color: $pomegranate;
    }
  }

  &--xl {
    font-size: 2.4rem;

    @include respond-to(tablet) {
      font-size: 2.1rem;
    }

    @include respond-to(small_tablet) {
      font-size: 1.8rem;
    }
  }

  &--xs {
    font-size: 1.4rem;
  }

  &--regular {
    font-weight: normal;
  }

  &--italic {
    font-style: italic;
  }

  &--center {
    text-align: center;
  }
}

.container {
  width: 1100px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;

  @include respond-to(desktop) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @include respond-to(small_tablet) {
    padding-right: 20px;
    padding-left: 20px;
  }

  &--xl {
    width: 1515px;
  }

  &--md {
    width: 1240px;
  }

  &--xs {
    width: 975px;
  }
}

.inner {
  position: relative;
  width: 990px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;

  &--md {
    width: 1025px;
  }

  &--xs {
    width: 770px;
  }
}

.page {

  &-main {
    padding-bottom: 40px;
    box-sizing: border-box;

    .content {
      padding: 120px 12% 0;
      // height: 100vh;

      @include respond-to(1599px) {
        padding: 120px 5% 0;
      }

      @include respond-to(laptop) {
        margin-top: 170px;
        padding: 0 30px;
      }

      @include respond-to(small_tablet) {
        margin-top: 100px;
        padding: 0 20px 50px;
      }
    }

    .population {
      position: absolute;
      top: 70px;
      left: calc(100% + 100px);
      // width: 397px;
      width: 500px;

      @include respond-to(1599px) {
        left: calc(100% + 50px);
      }

      @include respond-to(desktop) {
        top: 40px;
        width: 430px;
      }

      @include respond-to(laptop) {
        display: none;
      }

      &__num {

        span {
          font-size: 6.4rem;

          @include respond-to(desktop) {
            font-size: 5.4rem;
          }
        }
      }
    }
  }

  &-test {
    color: $white;
    padding-bottom: 40px;
    box-sizing: border-box;
  }

  &-saygakakh {

    .article {

      &__header {
        background-image: url(../img/content/bg/img_002.jpg);
      }
    }
  }

  &-pomoshch {

    .article {

      &__header {
        background-image: url(../img/content/bg/img_012.jpg);
      }
    }

    .section {

      &--bg {

        &-grass {
          margin-top: -970px;

          @include respond-to(desktop) {
            margin-top: -890px;
          }

          @include respond-to(tablet) {
            margin-top: -680px;
          }

          @include respond-to(fablet) {
            margin-top: -550px;
          }

          @include respond-to(small_phone) {
            margin-top: -350px;
          }
        }
      }

      &--gradient {

        &-chalky {
          height: 590px;
          padding: 0;

          @include respond-to(fablet) {
            height: 460px;
          }

          @include respond-to(small_phone) {
            height: 280px;
          }

          & + .section {
            margin-top: -95px;
          }
        }
      }

      &--wave {

        &-bottom {
          margin-top: 0;
        }
      }
    }
  }

  &-intervyu-s-fotografom {

    .article {

      &__header {
        background-image: url(../img/content/bg/img_015.jpg);
      }
    }
  }

  &-ekspeditsiya {

    .article {

      &__header {
        background-image: url(../img/content/bg/16.jpg);
      }
    }

    .section {

      &--wave {

        &-bottom {
          margin-top: 0;
        }
      }
    }
  }

  &-bg {
    background-position: center;
    background-repeat: no-repeat;

    &--main {
      min-height: 100vh;
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../img/content/bg/img_001.jpg);
      background-size: cover;
      //background-attachment: fixed;
    }

    &--test {
      min-height: 100vh;
      background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(../img/content/bg/img_008.jpg);
      background-size: cover;
      background-attachment: fixed;
    }
  }

  &-title {

    span {
      display: block;
    }
  }
}

.teaser {
  position: relative;
  width: 780px;
  max-width: 100%;
  padding: 60px 80px;
  border-radius: 60px;
  box-sizing: border-box;
  background-color: $white;

  @include respond-to(desktop) {
    width: 710px;
    padding-right: 55px;
    padding-left: 55px;
  }

  @include respond-to(laptop) {
    margin-right: auto;
    margin-left: auto;
  }

  @include respond-to(small_tablet) {
    padding: 30px;
    padding-bottom: 40px;
    border-radius: 20px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -124px;
    left: 110px;
    width: 161px;
    height: 131px;
    background-image: url(../img/general/icon/saigak/icon_saigak_white.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @include respond-to(small_tablet) {
      top: -74px;
      left: 60px;
      width: 99px;
      height: 80px;
    }
  }

  &__desc,
  .btn-list {
    margin-top: 30px;

    @include respond-to(small_tablet) {
      margin-top: 20px;
    }
  }

  &__desc {


    @include respond-to(small_tablet) {
      font-size: 1.6rem;
    }
  }

  .title {

    &--xxxl {
  
      @include respond-to(phone) {
        font-size: 5.3rem;
      }
    }

    &--md {
  
      @include respond-to(phone) {
        font-size: 2.3rem;
      }
    }
  }

  .btn {
  
    &--border {
      color: $mineShaft;

      &:hover {
        color: $white;
      }
    }
  }
}

.population {
  color: $creamCan;

  &__item {
    opacity: 0.97;

    &:nth-child(2) {
      margin-top: 40px;
      margin-left: 70px;

      @include respond-to(desktop) {
        margin-top: 20px;
        margin-left: 40px;
      }
    }

    &:nth-child(3) {
      margin-top: 40px;
      margin-left: 140px;

      @include respond-to(desktop) {
        margin-top: 20px;
        margin-left: 70px;
      }
    }

    &--md {

      .population {

        // &__img {
        //   width: 86px;
        //   height: 70px;
        // }

        // &__num {

        //   & > span {

        //     &:first-child {
        //       display: inline-block;
        //       min-width: 170px;
        //       min-width: 320px;
        //       font-size: 9.6rem;
        //     }

        //     &:last-child {
        //       font-size: 6.4rem;
        //     }
        //   }
        // }

        &__text {
          font-size: 2.4rem;
        }
      }
    }

    &--xs {

      .population {

        // &__img {
        //   width: 46px;
        //   height: 39px;
        // }

        // &__num {

        //   & > span {

        //     &:first-child {
        //       display: inline-block;
        //       // min-width: 110px;
        //       min-width: 165px;
        //       font-size: 7.2rem;
        //     }

        //     &:last-child {
        //       font-size: 4.8rem;
        //     }
        //   }
        // }

        // &__text {
        //   font-size: 1.7rem;
        // }
      }
    }
  }

  // &__img {
  //   background-image: url(../img/general/icon/saigak/icon_saigak_can.svg);
  //   background-position: center;
  //   background-size: contain;
  //   background-repeat: no-repeat;
  // }
  
  &__text {
    font-weight: normal;
    line-height: 150%;
    letter-spacing: 0.03em;
  }
}

.scroll-down {
  width: 36px;
  height: 80px;
  margin: 35px auto 0;
  background-image: url(../img/general/icon/icon_mouse.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: moveUpDown 1.3s infinite linear;

  @include respond-to(laptop) {
    width: 23px;
    height: 50px;
  }
}

@keyframes moveUpDown {
  0% {
    transform: translate3d(0, -10px, 0);
  }
  50% {
    transform: translate3d(0, 10px, 0);
  }
  100% {
    transform: translate3d(0, -10px, 0);
  }
}

.photograph {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 1.4rem;
  color: #ccc;
  letter-spacing: 1px;

  @include respond-to(small_tablet) {
    left: 20px;
  }

  &__main {
    @include respond-to(small_tablet) {
      bottom: initial;
      margin-top: 40px;
    }
  }

  &__article {
    position: relative;
    right: 0;
    left: 0;
    top: 20px;
    text-align: center;

    @include respond-to(small_tablet) {
      margin-bottom: 30px;
    }

    &--header {
      position: initial;
      margin-top: 16px;
    }
  
    &--without-top {
      margin-top: 0;
      top: 0;
    }
  }
}