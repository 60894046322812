@import "variables";
@import "mixins";

.start-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  // display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 40px;
  box-sizing: border-box;
  background-color: $cerulean;
}

.saigaks {
  position: relative;
  transform: translateY(-10%);
  display: flex;
  align-items: flex-end;

  &__item {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &--xl {
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      width: 598px;
      height: 486px;
      background-image: url(../img/general/icon/saigak/icon_saigak_big.svg);

      @include respond-to(laptop) {
        width: 344px;
        height: 279px;
      }

      @include respond-to(small_tablet) {
        width: 172px;
        height: 140px;
      }
    }

    &--md {
      width: 473px;
      height: 384px;
      background-image: url(../img/general/icon/saigak/icon_saigak.svg);

      @include respond-to(laptop) {
        width: 272px;
        height: 221px;
      }

      @include respond-to(small_tablet) {
        width: 136px;
        height: 111px;
      }
    }

    &--xs {
      order: 2;
      width: 398px;
      height: 324px;
      background-image: url(../img/general/icon/saigak/icon_saigak.svg);

      @include respond-to(laptop) {
        width: 229px;
        height: 186px;
      }

      @include respond-to(small_tablet) {
        width: 115px;
        height: 94px;
      }
    }
  }
}
