@import "variables";
@import "mixins";

.nav {
  display: flex;
  align-items: center;

  &-sticky {
    position: fixed;
    top: 100px;
    right: -100px;
    z-index: 50;
    display: flex;
    flex-direction: column;
    transition: $basicTransition;

    @include respond-to(fablet) {
      top: -100px;
      right: auto;
      left: 0;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: $black;
    }

    &.show {
      right: 0;

      @include respond-to(fablet) {
        top: 0;
      }
    }

    .share {
      height: 100%;
    }

    .social-list {
      position: absolute;
      top: 50%;
      right: 100px;
      transform: translateY(-50%);
      padding: 9px 40px;
      background-color: $black;

      @include respond-to(laptop) {
        right: 80px;
      }

      @include respond-to(fablet) {
        position: absolute;
        top: 65px;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        flex-direction: column;
        padding: 40px 12px;
      }

      &__item {

        &:not(:first-child) {

          @include respond-to(fablet) {
            margin-top: 25px;
            margin-left: 0;
          }
        }
      }
    }

    .btn {

      &-home,
      &-menu,
      &-share {
        width: 100%;
        height: 100%;
      }

      &-home {

        @include respond-to(laptop) {
          background-size: 25px;
        }
      }

      &-menu {
        display: flex;
        justify-content: center;
        align-items: center;

        &::before,
        &::after {
          left: auto;

          @include respond-to(laptop) {
            max-width: 34px;
          }
        }

        &::before {
          top: 27px;

          @include respond-to(laptop) {
            top: 19px;
          }
        }

        &::after {
          bottom: 27px;

          @include respond-to(laptop) {
            bottom: 19px;
          }
        }

        span {

          @include respond-to(laptop) {
            max-width: 34px;
          }
        }
      }

      &-share {
        background-position: center;
        background-size: auto;

        @include respond-to(laptop) {
          background-size: 25px;
        }

        &.active {

          @include respond-to(fablet) {
            transform: rotate(90deg);
          }
        }
      }
    }

    &__item {
      width: 80px;
      height: 80px;
      background-color: $black;
      transition: $basicTransition;

      @include respond-to(laptop) {
        width: 60px;
        height: 60px;
      }

      &:hover {
        background-color: $pomegranate;
      }
    }
  }

  &__item {

    &:not(:first-child) {
      margin-left: 35px;

      @include respond-to(desktop) {
        margin-left: 30px;
      }
    }

    // &:nth-child(-n + 6) {

    //   @include respond-to(laptop) {
    //     display: none;
    //   }
    // }

    &.desktop {

      @include respond-to(laptop) {
        display: none;
      }
    }

    &-link {
      font-size: 1.4rem;
      line-height: 150%;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: $white;
      transition: $basicTransition;

      @include respond-to(desktop) {
        font-size: 1.2rem;
      }

      @include respond-to(laptop) {
        font-size: 1.4rem;
      }

      &:hover,
      &.current {
        color: $silver;
      }

      &.current {
        pointer-events: none;
      }

      &.locked {
        display: flex;
        align-items: center;
        opacity: 0.5;
        pointer-events: none;

        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 19px;
          margin-top: -4px;
          margin-right: 5px;
          background-image: url(../img/general/icon/icon_lock.svg);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.storage-menu {
  left: 0;
  z-index: 100;
  display: grid;
  grid-template-columns: repeat(6, calc(100% / 6));
  grid-auto-rows: minmax(400px, 50vh);
  width: 100%;
  height: 100vh;
  overflow: auto;
  transition: all 1.2s ease-in-out;

  @include respond-to(laptop) {
    grid-template-columns: 100%;
    grid-auto-rows: 400px;
  }

  @include respond-to(small_tablet) {
    grid-auto-rows: 240px;
  }

  .btn-cross {
    display: none;
  }

  &__item {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 50px 20px;
    box-sizing: border-box;
    color: $white;

    @include respond-to(desktop) {
      padding: 30px 20px;
    }

    @include respond-to(small_tablet) {
      padding: 20px;
      padding-top: 20px;
      padding-bottom: 80px;
      padding-left: 20px;
      padding-right: 20px;
    }

    &:hover {

      &::before {
        background-image: url(../img/general/icon/saigak/icon_saigak.svg);
        background-color: rgba($cerulean, 0.8);
      }

      .btn--border {
        border-color: $white;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
      background-position: center right 50px;
      background-size: 50% auto;
      background-repeat: no-repeat;
      transition: $basicTransition;

      @include respond-to(desktop) {
        background-size: 45% auto;
      }

      @include respond-to(laptop) {
        background-position: center right 80px;
        background-size: 35% auto;
      }

      @include respond-to(small_tablet) {
        background-position: top 20px right 60px;
        background-size: 30% auto;
      }
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      grid-row: 1 / 2;

      @include respond-to(laptop) {
        grid-row: initial;
      }
    }

    &:nth-of-type(1) {
      grid-column: 1 / 4;

      @include respond-to(laptop) {
        grid-column: initial;
      }
    }

    &:nth-of-type(2) {
      grid-column: 4 / 7;

      @include respond-to(laptop) {
        grid-column: initial;
      }
    }

    &:nth-of-type(n + 3):nth-of-type(-n + 5) {
      grid-row: 2 / 3;

      @include respond-to(laptop) {
        grid-row: initial;
      }

      &::before {
        background-position: top 30px right 50px;
        background-size: 47% auto;

        @include respond-to(laptop) {
          background-position: center right 80px;
          background-size: 35% auto;
        }

        @include respond-to(small_tablet) {
          background-position: top 20px right 60px;
          background-size: 30% auto;
        }
      }
    }

    &:nth-of-type(3) {
      grid-column: 1 / 3;

      @include respond-to(laptop) {
        grid-column: initial;
      }
    }

    &:nth-of-type(4) {
      grid-column: 3 / 5;

      @include respond-to(laptop) {
        grid-column: initial;
      }
    }

    &:nth-of-type(5) {
      grid-column: 5 / 7;

      @include respond-to(laptop) {
        grid-column: initial;
      }
    }

    &.disable {
      pointer-events: none;
    }

    &.locked {
      pointer-events: none;

      .storage-menu {

        &__info {
          padding-left: 30px;

          &::before {
            display: block;
          }
        }
      }
    }

    @include respond-to(small_tablet) {
      
      .storage-menu__logo {
        left: 240px;
      }
    }

    @include respond-to(phone) {

      .storage-menu__logo {
        left: 200px;
      }
    }

    @include respond-to(small_phone) {

      .storage-menu__logo {
        top: 110px;
        left: 160px;
      }
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__logo {
    position: absolute;
    left: 50px;
    z-index: 10;
  }

  &__info {
    position: relative;
    z-index: 10;
    margin-top: auto;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 18px;
      height: 25px;
      background-image: url(../img/general/icon/icon_lock.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .btn {
      margin-top: 20px;

      &:hover {
        background-color: transparent;
      }
    }
  }

  &__title {

    .title {

      @include respond-to(1599px) {
        font-size: 5.8rem;
      }

      @include respond-to(desktop) {
        font-size: 5.2rem;
      }

      @include respond-to(small_tablet) {
        font-size: 3.6rem;
      }
    }

    span {
      display: block;

      &:first-child {
        font-size: 3.6rem;
        font-weight: 300;
        font-style: italic;
        line-height: 130%;

        @include respond-to(small_tablet) {
          font-size: 2.4rem;
        }
      }
    }
  }

  &--main {
    height: auto;
  }

  &--header {
    top: calc(-100% - 140px);
    position: fixed;

    &.show {
      top: 0;

      .btn-cross {

        @include respond-to(laptop) {
          right: 0;
        }
      }
    }

    .btn-cross {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
  
      @include respond-to(laptop) {
        position: fixed;
        right: -80px;
        width: 60px;
        height: 100vh;
        background-size: 30px 30px;
        transition: all 1.2s ease-in-out;
      }
  
      @include respond-to(small_tablet) {
        width: 40px;
        background-size: auto;
      }
      
      span {
  
        @include respond-to(laptop) {
          display: inline-block;
        }
      }
    }
  }
}
